@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --primary-main: #033c4d;
    --secondary-main: #ff7;
}

body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

