.tags-input-container {
  /* border: 2px solid #000; */
  /* padding: .5em; */
  /* border-radius: 3px; */
  width: min(80vw, 600px);
  margin: 1em 0em;
  /* display: flex; */
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5em;
}

.tag-item {
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 20px;
  margin: .25rem .25rem;

}
.tag-item .close {
  height: 20px;
  width: 20px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 18px;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  padding: 0.5em 0.5em;
  /* border: 1.5px solid rgba(128, 128, 128, 0.524); */
  border-radius: 0.25rem;
  outline: none;
}
