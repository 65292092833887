/* Profile.css */

.card-title {
  font-size: 1.5rem;
}

.input-group-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #033c4d;
  color: #fff;
  padding: 0.5rem;
  border-radius: 5rem;
  margin-right: 0.5rem;
}
.pass-inputs {
  outline: none;
}

/* Add more styles as needed */
